import {
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../components/Button";
import { User } from "../../../api/types";
import { useClient } from "../../../components/client/Client";
import { useLoader } from "../../../components/Loader";
import { BankAccounts } from "./components/BankAccounts";
import { BasicInformation } from "./components/BasicInformation";
import { AccountingInformationView } from "./components/AccountingInformation";
import { CloseIcon } from "../../../components/icons";

export const EditUserDetail: FC = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { Loader, done } = useLoader();
  const { client } = useClient();

  const [user, setUser] = useState<User>({} as User);

  const close = () => {
    navigate("..", { relative: "path" });
  };

  useEffect(() => {
    (async () => {
      if (!userId) {
        close();
        return;
      }

      setUser(await client.getUser(parseInt(userId)));

      done();
    })();
  }, [userId]);

  return (
    <Dialog open={true} onClose={close} scroll="paper">
      <Container>
        {Loader(
          <>
            <DialogTitle>User details {user?.email}</DialogTitle>
            <IconButton
              onClick={close}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>

            <DialogContent dividers={true}>
              <Grid container spacing={2}>
                <BasicInformation
                  user={user}
                  editMode={true}
                  onSaveClick={async (user: User) => {
                    const result = await client.updateUser(user);
                    setUser(result);
                    return result;
                  }}
                />
                <AccountingInformationView user={user} />
                <BankAccounts user={user} />
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button onClick={close}>Close</Button>
            </DialogActions>
          </>
        )}
      </Container>
    </Dialog>
  );
};
