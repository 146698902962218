import Ajv, { ErrorObject, JSONSchemaType } from "ajv";
import addFormats from "ajv-formats";
import addCustomErrors from "ajv-errors";
import { UserWithPassword, User, AccountingInformation } from "../api/types";

import { isValidIBAN, electronicFormatIBAN } from "ibantools";

export type ValidationErrors<T = any> = Partial<{ [key in keyof T]: string }>;
export const transformErrors = <T>(
  errors: ErrorObject<string, Record<string, any>, unknown>[] | null | undefined
) =>
  errors?.reduce((validationErrors, { instancePath, message }) => {
    validationErrors[instancePath.substring(1) as keyof T] = message;
    return validationErrors;
  }, {} as ValidationErrors<T>);

export const ajv = new Ajv({ allErrors: true, $data: true });
addFormats(ajv);
addCustomErrors(ajv);

ajv.addFormat("iban", {
  type: "string",
  validate: (v: string) => {
    const iban = electronicFormatIBAN(v);
    return !!iban && isValidIBAN(iban);
  },
});

const userSchema: JSONSchemaType<User> = {
  type: "object",
  properties: {
    id: { type: "number" },
    companyId: { type: "number" },
    email: { type: "string", format: "email", minLength: 1 },
    firstname: { type: "string", minLength: 1 },
    lastname: { type: "string", minLength: 1 },
    createdOn: { type: "string" },
    createdBy: { type: "string" },
    modifiedOn: { type: "string" },
    modifiedBy: { type: "string" },
  },
  required: ["email", "firstname", "lastname"],
  additionalProperties: false,
  errorMessage: {
    properties: {
      email: "must be valid email address",
    },
  },
};
export const validateUser = ajv.compile(userSchema)!;

const createUserSchema: JSONSchemaType<UserWithPassword> = {
  type: "object",
  properties: {
    companyId: { type: "number" },
    email: { type: "string", format: "email", minLength: 1 },
    password: { type: "string", nullable: true },
    firstname: { type: "string", minLength: 1 },
    lastname: { type: "string", minLength: 1 },
  },
  required: ["companyId", "email", "firstname", "lastname"],
  additionalProperties: false,
  errorMessage: {
    properties: {
      email: "must be valid email address",
    },
  },
};
export const validateCreateUser = ajv.compile(createUserSchema)!;

const emailSchema: JSONSchemaType<string> = {
  type: "string",
  format: "email",
  errorMessage: {
    format: "invalid email address",
  },
};
export const validateEmail = ajv.compile(emailSchema);

const ibanSchema: JSONSchemaType<string> = {
  type: "string",
  format: "iban",
  errorMessage: {
    format: "invalid IBAN",
  },
};
export const validateIBAN = ajv.compile(ibanSchema);

const resetPasswordSchema: JSONSchemaType<{
  email: string;
  token: string;
  password: string;
  verificationPassword: string;
}> = {
  type: "object",
  properties: {
    email: { type: "string", format: "email" },
    token: { type: "string", minLength: 6, maxLength: 6 },
    password: { type: "string", minLength: 1 },
    verificationPassword: {
      type: "string",
      minLength: 1,
    },
  },
  if: {
    properties: {
      verificationPassword: {
        type: "string",
      },
    },
  },
  then: {
    properties: {
      verificationPassword: {
        const: { $data: "1/password" },
      },
    },
  },
  required: ["email", "token", "password", "verificationPassword"],
  errorMessage: {
    properties: {
      email: "must be a valid email address",
      verificationPassword: "must match password",
    },
  },
};
export const validateResetPassword = ajv.compile(resetPasswordSchema);

const accountingInformationSchema: JSONSchemaType<AccountingInformation> = {
  type: "object",
  properties: {
    id: { type: "number" },
    userId: { type: "number" },
    companyNumber: { type: "string", minLength: 1 },
    businessPartnerNumber: { type: "string", minLength: 1 },
    costCenter: { type: "string", minLength: 1 },
  },
  required: ["companyNumber", "businessPartnerNumber", "costCenter"],
  additionalProperties: false,
};
export const validateAccountingInformation = ajv.compile(accountingInformationSchema)!;