import { ChangeEvent, FC, useState } from "react";
import { TextField } from "../../components/TextField";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../components/Button";
import { validateEmail } from "../../validation";
import { useClient } from "../../components/client/Client";
import { useFeedback } from "../../components/feedback/Index";

export const RequestToken: FC = () => {
  const { client } = useClient();
  const { showToast } = useFeedback();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const onChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  };

  const onRequestToken = async () => {
    const isValid = validateEmail(email);

    if (!isValid) {
      setError(validateEmail.errors?.[0].message ?? "unknown error");
      return;
    }

    setError("");

    try {
      await client.requestPasswordToken(email);
    } catch (err) {
      showToast("Failed to request token", { severity: "error" });
      return;
    }

    navigate(`token?email=${email}sent`, { relative: "route" });
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onRequestToken();
      return;
    }
  };

  return (
    <>
      <TextField
        value={email}
        onChange={onChangeEmail}
        margin="normal"
        required
        error={!!error}
        helperText={error}
        fullWidth
        id="email"
        label="Email Address"
        autoComplete="username"
        onKeyDown={onKeyDown}
        type="email"
        autoFocus
        variant="outlined"
      />
      <Button onClick={onRequestToken} fullWidth sx={{ mt: 3, mb: 2 }}>
        Request token
      </Button>

      <Link to="token">
        <Button fullWidth>I already have a token</Button>
      </Link>
    </>
  );
};
