import { AppBar, Box, Toolbar } from "@mui/material";
import { FC } from "react";

import Logo from "../assets/Logo-Color-512.png";
import { Button } from "./Button";
import { useClient } from "./client/Client";

export const MenuBar: FC = () => {
  const { client, me } = useClient();

  return (
    <AppBar
      position="static"
      color="transparent"
      sx={{ boxShadow: "none", borderBottom: "1px solid #ddd" }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "left",
          backgroundColor: "#fff",
        }}
        variant="dense"
      >
        <Box component="img" src={Logo} alt="logo" sx={{ width: 48 }} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "right",
            columnGap: 2,
            alignItems: "center",
            flexGrow: 1,
          }}
        >
          Hi, {me.firstname}!
          <Button
            color="inherit"
            variant="outlined"
            size="small"
            onClick={() => client.logout()}
          >
            Logout
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
