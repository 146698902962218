import { FC } from "react";
import { useQuery } from "../../hooks/useQuery";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../components/Button";
import { validateResetPassword } from "../../validation";
import { useClient } from "../../components/client/Client";
import { useFeedback } from "../../components/feedback/Index";
import { Form, InputProps, InputType } from "../../components/Form";
import { TextFieldProps } from "@mui/material";

interface ResetPassword {
  email: string;
  token: string;
  password: string;
  verificationPassword: string;
}

export const ResetPassword: FC = () => {
  const query = useQuery();
  const navigate = useNavigate();

  const { client } = useClient();
  const { showToast } = useFeedback();

  const wasSent = !!query.has("sent");

  const initialResetPassword: ResetPassword = {
    email: query.get("email") ?? "",
    token: query.get("token") ?? "",
    password: "",
    verificationPassword: "",
  };

  const textFieldProps: TextFieldProps = {
    fullWidth: true,
    variant: "outlined",
  };

  const inputs: InputProps<ResetPassword>[] = [
    {
      valueProp: "email",
      type: InputType.text,
      label: "Email address",
      autoComplete: "username",
      required: true,
    },
    {
      valueProp: "token",
      type: InputType.text,
      label: "Token",
      required: true,
    },
    {
      valueProp: "password",
      type: InputType.password,
      label: "Password",
      required: true,
      autoComplete: true,
    },
    {
      valueProp: "verificationPassword",
      type: InputType.password,
      label: "Verify Password",
      required: true,
      autoComplete: false,
    },
  ];

  const onResetPassword = async ({ email, token, password }: ResetPassword) => {
    try {
      await client.resetPassword(email, token, password);
    } catch (err) {
      showToast("Failed to reset password", { severity: "error" });
      console.error(err);
      return;
    }

    navigate("/login");
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onResetPassword({} as any);
      return;
    }
  };

  return (
    <>
      {wasSent && <div>A token was sent to your mail address.</div>}
      <Form
        inputs={inputs}
        validate={validateResetPassword}
        initialValue={initialResetPassword}
        onSubmit={onResetPassword}
        textFieldProps={textFieldProps}
      >
        {({ inputs, submit }) => (
          <>
            {inputs}
            <Button onClick={submit} fullWidth sx={{ mt: 3, mb: 2 }}>
              Reset password
            </Button>
          </>
        )}
      </Form>

      <Link to="../" relative="route">
        <Button fullWidth>I don&apos;t have a token</Button>
      </Link>
    </>
  );
};
