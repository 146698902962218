import { FC } from "react";
import { Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TagsIcon from '@mui/icons-material/LocalOffer';
import UsersIcon from '@mui/icons-material/Group';
import { SidebarItem } from "./SidebarItem";

const drawerWidth = '200px';
const drawerMaginTop = '48px';

const sidebarItems: SidebarItem[] = [
    { title: "Users", uri: "/users", icon: UsersIcon },
    { title: "Tags", uri: "/tags", icon: TagsIcon }
];

export const Sidebar: FC = () => {

    const navigate = useNavigate();

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    marginTop: drawerMaginTop,
                    boxSizing: 'border-box',
                },
            }}
            variant="permanent"
            anchor="left">

            <Divider />
            <List>
                {sidebarItems.map((sidebarItem) => (
                    <ListItem key={`sidebar-${sidebarItem.title}`} disablePadding>
                        <ListItemButton
                            onClick={() => navigate(sidebarItem.uri)}
                        >
                            <ListItemIcon>
                                <sidebarItem.icon />
                            </ListItemIcon>
                            <ListItemText primary={sidebarItem.title} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Drawer>
    )
};