import { AlertColor } from "@mui/material";
import { ToastProps } from "./Toast";
import { useCallback, useState } from "react";

interface ShowToastProps {
  duration?: number;
  severity?: AlertColor;
}

export type ShowToast = (message: string, opts?: ShowToastProps) => void;

const defaultDuration = 6_000; // ms
const defaultSeverity: AlertColor = "success";

export const useToast = () => {
  const [toastProps, setToastProps] = useState<ToastProps>({
    open: false,
    duration: defaultDuration,
    severity: defaultSeverity,
    message: "",
    onClose: () => {
      /* default */
    },
  });

  const showToast: ShowToast = useCallback(
    (
      message,
      { duration = defaultDuration, severity = defaultSeverity } = {}
    ) => {
      const onClose = () =>
        setToastProps((props) => ({ ...props, open: false }));

      setToastProps({
        open: true,
        duration,
        severity,
        message,
        onClose,
      });
    },
    []
  );

  return { showToast, toastProps };
};
