import { Box, Divider, IconButton, Paper, Typography } from "@mui/material";
import { FC, PropsWithChildren } from "react";
import { EditIcon } from "./icons";

export interface CardProps extends PropsWithChildren {
  title: string;
  onEdit?: () => void;
}

export const Card: FC<CardProps> = ({ title, children, onEdit }) => {
  return (
    <Paper>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5" sx={{ padding: "5px" }}>
          {title}
        </Typography>
        {onEdit && (
          <IconButton onClick={onEdit}>
            <EditIcon />
          </IconButton>
        )}
      </Box>
      <Divider />

      <Box sx={{ padding: "5px" }}>{children}</Box>
    </Paper>
  );
};
