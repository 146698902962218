import { useCallback, useState } from "react";
import { ConfirmDialogProps } from "./ConfirmDialog";
import { NoOp } from "./noop";

interface ConfirmProps {
  title: string;
  children?: React.ReactNode;
  onConfirm: (close: () => void) => void;
  onCancel?: () => void;
}

export type ShowConfirm = (opts: ConfirmProps) => void;

export const useConfirm = () => {
  const [confirmProps, setConfirmProps] = useState<ConfirmDialogProps>({
    open: false,
    title: "",
    onConfirm: NoOp,
    onCancel: NoOp,
  });

  const showConfirm: ShowConfirm = useCallback(
    ({ title, children, onConfirm, onCancel }) => {
      const close = () =>
        setConfirmProps((props) => ({ ...props, open: false }));

      setConfirmProps({
        open: true,
        title,
        children,
        onConfirm: () => {
          onConfirm(close);
        },
        onCancel: () => {
          onCancel?.();
          close();
        },
      });
    },
    []
  );

  return { showConfirm, confirmProps };
};
