import { FC, useEffect, useState } from "react";
import { AccountingInformation, User } from "../../../../api/types";
import { Box, Grid } from "@mui/material";
import { Card } from "../../../../components/Card";
import { useClient } from "../../../../components/client/Client";
import { useLoader } from "../../../../components/Loader";
import { TextField } from "../../../../components/TextField";
import { Button } from "../../../../components/Button";
import { useFeedback } from "../../../../components/feedback/Index";
import {
  ValidationErrors,
  transformErrors,
  validateAccountingInformation
} from "../../../../validation";

export interface AccountingInformationProps {
  user: User;
}

export const AccountingInformationView: FC<AccountingInformationProps> = ({
  user,
}) => {

  const isEditMode = user.id !== undefined;

  const { client } = useClient();
  const { showToast } = useFeedback();
  const { Loader, done } = useLoader();

  const [errors, setErrors] = useState<ValidationErrors<AccountingInformation>>();

  const [updatedAccountingInformation, setUpdatedAccountingInformation] = useState<AccountingInformation>({} as AccountingInformation);

  useEffect(() => {
    if (!isEditMode) {
      setUpdatedAccountingInformation({
        businessPartnerNumber: "",
        companyNumber: "",
        costCenter: "",
      } as AccountingInformation);
      done();
      return;
    }

    (async () => {
      const accountingInfo = await client.getAccountingInformation(user);
      setUpdatedAccountingInformation(accountingInfo);
      done();
    })();
  }, []);

  const saveAccountingInformation = async () => {
    if (!user.id) {
      showToast("Please save Basic Information first", { severity: "warning" });
      return;
    }

    const isValid = validateAccountingInformation(updatedAccountingInformation);
    if (!isValid) {
      setErrors(transformErrors(validateAccountingInformation.errors));
      return;
    }

    setErrors(undefined);

    try {
      await client.updateAccountingInformation(user, updatedAccountingInformation);
      showToast("Accounting information saved", { severity: "success" });
    } catch (err) {
      showToast("Could not save accounting information", { severity: "error" });
      return;
    }
  };

  const changeUpdatedAccountingInformation = (value: string, field: string) => {
    setUpdatedAccountingInformation({ ...updatedAccountingInformation, [field]: value });
  }

  return (
    <Grid item xs={12}>
      <Card title="Accounting Information">
        {Loader(
          <>
            <Grid container>
              <Grid item xs={6}>
                <TextField
                  className="text-field"
                  label="Company Number"
                  value={updatedAccountingInformation?.companyNumber ?? ""}
                  onChange={e => changeUpdatedAccountingInformation(e.target.value, 'companyNumber')}
                  error={!!errors?.companyNumber}
                  helperText={errors?.companyNumber}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className="text-field"
                  label="Business Partner Number"
                  value={updatedAccountingInformation?.businessPartnerNumber ?? ""}
                  onChange={e => changeUpdatedAccountingInformation(e.target.value, 'businessPartnerNumber')}
                  error={!!errors?.businessPartnerNumber}
                  helperText={errors?.businessPartnerNumber}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className="text-field"
                  label="Cost Center"
                  value={updatedAccountingInformation?.costCenter ?? ""}
                  onChange={e => changeUpdatedAccountingInformation(e.target.value, 'costCenter')}
                  error={!!errors?.costCenter}
                  helperText={errors?.costCenter}
                />
              </Grid>
            </Grid>

            <Box className="save-button-container">
              <Button onClick={saveAccountingInformation}>Save</Button>
            </Box>
          </>
        )}
      </Card>
    </Grid>
  );
};
