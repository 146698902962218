import { FC, useState } from "react";
import { User } from "../../../../api/types";
import { Box, Grid } from "@mui/material";
import { Card } from "../../../../components/Card";
import { TextField } from "../../../../components/TextField";
import { Button } from "../../../../components/Button";
import { useFeedback } from "../../../../components/feedback/Index";
import {
  ValidationErrors,
  transformErrors,
  validateUser
} from "../../../../validation";

export interface BasicInformationProps {
  user: User;
  editMode: boolean
  onSaveClick: (user: User) => Promise<User>
}

export const BasicInformation: FC<BasicInformationProps> = ({
  user,
  editMode,
  onSaveClick,
}) => {
  const { showToast } = useFeedback();

  const [errors, setErrors] = useState<ValidationErrors<User>>();

  const [updatedUser, setUpdatedUser] = useState<User>(user as User);

  const updateUser = (value: string, field: keyof User) => {
    setUpdatedUser({ ...updatedUser, [field]: value })
  }
  const saveUser = async () => {
    const isValid = validateUser(updatedUser);
    if (!isValid) {
      setErrors(transformErrors(validateUser.errors));
      return;
    }

    setErrors(undefined);

    try {
      await onSaveClick(updatedUser);
      showToast("Basic information saved", { severity: "success" });
    } catch (err) {
      showToast("Could not save basic information", { severity: "error" });
    }
  };

  return (
    <Grid item xs={12}>
      <Card title="Basic Information">
        <Grid container>
          <Grid item xs={6}>
            <TextField
              className="text-field"
              label="Firstname"
              value={updatedUser?.firstname ?? ""}
              onChange={e => updateUser(e.target.value, 'firstname')}
              required={!editMode}
              error={!!errors?.firstname}
              helperText={errors?.firstname}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              className="text-field"
              label="Lastname"
              value={updatedUser?.lastname ?? ""}
              onChange={e => updateUser(e.target.value, 'lastname')}
              required={!editMode}
              error={!!errors?.lastname}
              helperText={errors?.lastname}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              className="text-field"
              label="Email"
              value={updatedUser?.email ?? ""}
              onChange={e => updateUser(e.target.value, 'email')}
              required={!editMode}
              error={!!errors?.email}
              helperText={errors?.email}
            />
          </Grid>
        </Grid>

        <Box className="save-button-container">
          <Button onClick={saveUser}>Save</Button>
        </Box>

      </Card>
    </Grid>
  );
};
