import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IconButton } from "@mui/material";
import { Button } from "../Button";
import { Column } from "./Column";
import { Action } from "./Action";
import { PropsWithChildren, ReactElement } from "react";

export interface TableProps<T> {
  columns: Column<T>[];
  rows: T[];
  actions?: Action<T>[];
}

export const Table = <T,>({
  columns,
  rows,
  actions,
}: PropsWithChildren<TableProps<T>>): ReactElement => {
  return (
    <TableContainer
      component={Paper}
      variant="outlined"
      sx={{ borderRadius: 4 }}
    >
      <MuiTable sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((col, i) => (
              <TableCell
                key={`col-${i}`}
                variant="head"
                sx={{ fontWeight: 700 }}
              >
                {col.title ?? String(col.key)}
              </TableCell>
            ))}
            {!!actions && <TableCell align="right" width="130px" />}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <TableRow
              key={`row-${i}`}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {columns.map((col, k) => (
                <TableCell
                 key={`row-${i}-col-${k}`}>
                  {row[col.key] as any}
                </TableCell>
              ))}
              {!!actions && (
                <TableCell align="right">
                  {actions.map((action, k) => (
                    <span key={`row-${i}-action-${k}`}>
                      {(action.icon && (
                        <IconButton
                          aria-label={action.title}
                          title={action.title}
                          size="large"
                          disabled={action.disabled?.(row)}
                          onClick={() => action.onClick(row)}
                        >
                          {action.icon}
                        </IconButton>
                      )) || (
                          <Button
                            onClick={() => action.onClick(row)}
                            disabled={action.disabled?.(row)}
                          >
                            {action.title}
                          </Button>
                        )}
                    </span>
                  ))}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};
