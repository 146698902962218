import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ApiClient, Events } from "../../api";
import { useLoader } from "../Loader";
import { User } from "../../api/types";
import { useFeedback } from "../feedback/Index";

export interface ClientContext {
  client: ApiClient;
  isLoggedIn: boolean;
  me: User;
}

export const ClientContext = createContext<ClientContext>({
  client: {} as ApiClient,
  isLoggedIn: false,
  me: {} as User,
});

export const useClient = () => {
  return useContext(ClientContext);
};

export interface ClientProps {
  url: string;
  children: ReactNode;
}

export const Client: FC<ClientProps> = ({ url, children }) => {
  const { Loader, done } = useLoader();
  const { showToast } = useFeedback();

  const client = useMemo(() => new ApiClient(url), []);
  const [isLoggedIn, setIsLoggedIn] = useState(client.isLoggedIn);
  const [me, setMe] = useState<User>(client.me ?? ({} as User));

  const context = useMemo<ClientContext>(
    () => ({
      client,
      isLoggedIn,
      me,
    }),
    [client, isLoggedIn, me]
  );

  useEffect(() => {
    const destroy = client.init();

    const onLogin = () => setIsLoggedIn(true);
    const onLogout = () => setIsLoggedIn(false);
    const onUnauthorized = () => {
      showToast("You are not logged in", { severity: "error" });
      setIsLoggedIn(false);
    };
    const onChangeMe = () => setMe(client.me ?? ({} as User));

    client.on(Events.login, onLogin);
    client.on(Events.logout, onLogout);
    client.on(Events.unauthorized, onUnauthorized);
    client.on(Events.changeMe, onChangeMe);

    setIsLoggedIn(client.isLoggedIn);

    (async () => {
      if (client.isLoggedIn) {
        await client.load();
      }
      done();
    })();

    return () => {
      client.off(Events.login, onLogin);
      client.off(Events.logout, onLogout);
      client.off(Events.unauthorized, onUnauthorized);
      client.off(Events.changeMe, onChangeMe);

      destroy();
    };
  }, []);

  return (
    <ClientContext.Provider value={context}>
      {Loader(children)}
    </ClientContext.Provider>
  );
};
