import { FC, useState } from "react";
import { BankAccount, User } from "../../../../api/types";
import { Container, Grid, List, ListItem, ListItemButton } from "@mui/material";
import { Card } from "../../../../components/Card";
import { DeleteIcon } from "../../../../components/icons";
import { TextField } from "../../../../components/TextField";
import { useFeedback } from "../../../../components/feedback/Index";
import { useClient } from "../../../../components/client/Client";
import { validateIBAN } from "../../../../validation";
import { Button } from "../../../../components/Button";
import { useLoader } from "../../../../components/Loader";

export interface BankAccountsProps {
  user: User;
}

export const BankAccounts: FC<BankAccountsProps> = ({ user }) => {
  const { client } = useClient();

  const isEditMode = user.id !== undefined;

  const {
    Loader,
    data: bankAccounts,
    reload,
  } = useLoader(() => {
    if (isEditMode) {
      return client.getBankAccounts(user)
    } else {
      return Promise.resolve([]);
    }
  }, []);

  const { showConfirm, showToast } = useFeedback();

  const [newBankAccount, setNewBankAccount] = useState<BankAccount>("");
  const [ibanError, setIbanError] = useState("");

  const onDeleteBankAccount = (bankAccount: BankAccount) => {
    if (!user.id) return;

    showConfirm({
      title: "Delete bank account?",
      children: `Do you really want to remove bank account ${bankAccount} from ${user.email}?`,
      onConfirm: async (close) => {
        try {
          await client.deleteBankAccount(user, bankAccount);
        } catch (err) {
          showToast(`Could not remove bank account ${bankAccount}`, {
            severity: "error",
          });
          close();
          return;
        }

        showToast(`Removed bank account ${bankAccount}`);

        reload();
        close();
      },
    });
  };

  const onAddBankAccount = async () => {
    if (!user.id) {
      showToast("Please save Basic Information first", { severity: "warning" });
      return;
    }

    const isValid = validateIBAN(newBankAccount);
    if (!isValid) {
      setIbanError(validateIBAN.errors?.[0].message ?? "");
      return;
    }

    setIbanError("");

    try {
      await client.addBankAccount(user, newBankAccount);
    } catch (err) {
      showToast("Could not add bank account", { severity: "error" });
      return;
    }

    setNewBankAccount("");

    reload();
  };
  return (
    <Grid item xs={12}>
      <Card title="Bank Accounts">
        <Container>
          {Loader(
            <List>
              {bankAccounts?.map((bankAccount) => (
                <ListItem key={bankAccount}>
                  {bankAccount}
                  <ListItemButton
                    className="remove-bank-account-button"
                    onClick={() => onDeleteBankAccount(bankAccount)}
                  >
                    <DeleteIcon />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}

          <TextField
            className="text-field"
            value={newBankAccount ?? ""}
            required
            error={!!ibanError}
            helperText={ibanError}
            onChange={(event) => setNewBankAccount(event.currentTarget.value)}
            label="IBAN"
          />

          <Button className="add-bank-account-button" onClick={onAddBankAccount}>Add</Button>
        </Container>
      </Card>
    </Grid>
  );
};
