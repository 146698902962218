import { Box, LinearProgress } from "@mui/material";
import { FC } from "react";

export const Loading: FC = () => {
  return (
    <Box
      sx={{
        position: "relative",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        width: "100px",
        height: "20px",
      }}
    >
      Loading...
      <LinearProgress />
    </Box>
  );
};
