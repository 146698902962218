import EventEmitter from "eventemitter3";

import {
  User,
  AuthResponse,
  RefreshTokenResponse,
  BankAccount,
  AccountingInformation,
  Tag,
} from "./types";

export interface RequestOptions {
  json?: boolean;
}

export const isDebug = process.env.NODE_ENV === "development";

const authTokenKey = "auth-token";
const authTokenExpiresAtKey = "auth-token-expires-at";

const refreshTime = 30_000; // milliseconds

const now = (additional = 0) => new Date().getTime() + additional;

const UsersUrl = (userOrId?: User | number) =>
  userOrId
    ? `/users/${typeof userOrId === "number" ? userOrId : userOrId.id}`
    : "/users";
const BankAccountsUrl = (user: User) => `${UsersUrl(user)}/bank-accounts`;
const AccountingInformationUrl = (user: User) =>
  `${UsersUrl(user)}/accounting-information`;
const TagsUrl = (tag?: Tag): string => {
  return tag
    ? `/tags/${tag.id}`
    : "/tags";
};

function getConfig(key: string): string | undefined;
function getConfig(key: string, defaultValue: string): string;
function getConfig(key: string, defaultValue?: string): string | undefined {
  return localStorage.getItem(key) ?? defaultValue;
}
const setConfig = (key: string, value: string) =>
  localStorage.setItem(key, value);

export class ClientError extends Error {
  statusCode?: number;

  constructor(
    message: string,
    options?: { statusCode?: number } & ErrorOptions
  ) {
    super(message, options);

    this.statusCode = options?.statusCode;
  }
}

export enum Events {
  login = "login",
  logout = "logout",
  unauthorized = "unauthorized",
  changeMe = "change:me",
}

export class ApiClient extends EventEmitter {
  private baseUrl = "";

  private authToken = "";
  private expiresAt = 0; // timestamp in ms

  private myself?: User;

  private refreshTimer?: NodeJS.Timeout = undefined;

  constructor(baseUrl: string) {
    super();

    this.baseUrl = baseUrl;
  }

  init() {
    this.setAuthToken(
      getConfig(authTokenKey, ""),
      +getConfig(authTokenExpiresAtKey, "0")
    );

    return () => {
      this.refreshTimer && clearTimeout(this.refreshTimer);
    };
  }

  get me() {
    return this.myself;
  }

  get isLoggedIn() {
    return !!this.authToken && !this.isTokenExpired;
  }

  get isTokenExpired() {
    return now() > this.expiresAt;
  }

  async login(email: string, password: string) {
    const { expiresIn, token } = await this.post<AuthResponse>("/auth/login", {
      email,
      password,
    });

    this.setAuthToken(token, now(expiresIn * 1000));

    await this.load();

    this.emit(Events.login);
  }

  logout() {
    this.setAuthToken("", 0);
    this.emit(Events.logout);
  }

  async refreshToken() {
    const { expiresIn, token } = await this.post<RefreshTokenResponse>(
      "/auth/refresh"
    );

    this.setAuthToken(token, now(expiresIn * 1000));
  }

  async load() {
    await this.loadMyself();
  }

  async loadMyself() {
    this.myself = await this.get<User>("/users/myself");

    this.emit(Events.changeMe, this.myself);
  }

  requestPasswordToken(email: string) {
    return this.post("/users/password-reset", { email });
  }

  resetPassword(email: string, token: string, password: string) {
    return this.post("/users/password-reset", {
      email,
      token,
      newPassword: password,
    });
  }

  getUsers() {
    return this.get<User[]>(UsersUrl());
  }

  getUser(userId: number) {
    return this.get<User>(UsersUrl(userId));
  }

  addUser(user: User) {
    return this.post<User>(UsersUrl(), user);
  }

  async updateUser(user: User) {
    const updatedUser = await this.put<User>(UsersUrl(user), user);

    if (updatedUser.id === this.me?.id) {
      await this.loadMyself();
    }

    return updatedUser;
  }

  deleteUser(user: User) {
    return this.delete<void>(UsersUrl(user));
  }

  getBankAccounts(user: User) {
    return this.get<BankAccount[]>(BankAccountsUrl(user));
  }

  addBankAccount(user: User, bankAccount: BankAccount) {
    return this.post<void>(BankAccountsUrl(user), bankAccount);
  }

  deleteBankAccount(user: User, bankAccount: BankAccount) {
    return this.delete<void>(`${BankAccountsUrl(user)}/${bankAccount}`);
  }

  getAccountingInformation(user: User) {
    return this.get<AccountingInformation>(AccountingInformationUrl(user));
  }

  updateAccountingInformation(
    user: User,
    accountingInformation: AccountingInformation
  ) {
    return this.put<AccountingInformation>(
      AccountingInformationUrl(user),
      accountingInformation
    );
  }

  deleteAccountingInformation(user: User) {
    return this.delete<void>(AccountingInformationUrl(user));
  }

  getTags() {
    return this.get<Tag[]>(TagsUrl());
  }

  updateTag(tag: Tag) {
    return this.put<Tag>(TagsUrl(tag), tag);
  }

  private setAuthToken(token: string, expiresAt: number) {
    this.authToken = token;
    this.expiresAt = expiresAt;

    if (!token || this.isTokenExpired) {
      if (this.refreshTimer) clearTimeout(this.refreshTimer);

      localStorage.removeItem(authTokenKey);
      localStorage.removeItem(authTokenExpiresAtKey);
    } else {
      setConfig(authTokenKey, token);
      setConfig(authTokenExpiresAtKey, `${expiresAt}`);

      this.refreshTimer = setTimeout(async () => {
        if (this.refreshTimer) {
          clearTimeout(this.refreshTimer);
          this.refreshTimer = undefined;
        }

        try {
          await this.refreshToken();
        } catch (err) {
          if (err instanceof ClientError && err.statusCode === 401) {
            this.emit(Events.unauthorized);
            return;
          }

          throw err;
        }
      }, expiresAt - now() - refreshTime);
    }
  }

  private async get<T>(path: string, options?: RequestOptions) {
    return this.sendRequest<T>("get", path, undefined, options);
  }

  private async post<T>(path: string, data?: any, options?: RequestOptions) {
    return this.sendRequest<T>("post", path, data, options);
  }

  private async put<T>(path: string, data?: any, options?: RequestOptions) {
    return this.sendRequest<T>("put", path, data, options);
  }

  private async delete<T>(path: string, options?: RequestOptions) {
    return this.sendRequest<T>("delete", path, undefined, options);
  }

  private async sendRequest<T>(
    method: "get" | "post" | "put" | "delete",
    path: string,
    data: any,
    { json = true }: RequestOptions = {}
  ) {
    if (!path.startsWith("/")) path = `/${path}`;

    const headers = new Headers();
    if (json) {
      headers.set("content-type", "application/json");
    }

    if (this.authToken) {
      headers.set("authorization", `Bearer ${this.authToken}`);
    }

    const response = await fetch(`${this.baseUrl}${path}`, {
      method,
      headers,
      body: data ? (json ? JSON.stringify(data) : data) : undefined,
    });

    if (response.status < 200 || response.status >= 400) {
      if (response.status === 401) {
        this.setAuthToken("", 0);
      }

      throw new ClientError(
        `request failed (${response.status}): ${await response.text()}`,
        { statusCode: response.status }
      );
    }

    if (response.status === 204) return undefined as T;

    return (json ? await response.json() : response) as Promise<T>;
  }
}
