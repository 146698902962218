import { FC, } from "react";
import { blue } from '@mui/material/colors';


export interface ColorCircleProps {
    color?: string
}

const shade = 500;
const defaultTagColor = blue[shade];

export const ColorPickerCircle: FC<ColorCircleProps> = ({ color }) => {
    return (
        <span className="tag-color" style={{ backgroundColor: color ? color : defaultTagColor }}></span>
    )
};