import { Container, Divider, Paper, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useClient } from "../../components/client/Client";
import { useFeedback } from "../../components/feedback/Index";
import { Tag } from "../../api/types";
import { TextField } from "../../components/TextField";
import { useLoader } from "../../components/Loader";
import { ColorPickerCircle } from "../../components/colorpicker/ColorCircle";
import { ColorPicker } from "../../components/colorpicker/ColorPicker";

export const Tags: FC = () => {

    const { client } = useClient();
    const { Loader, done } = useLoader();
    const { showToast } = useFeedback();

    const [tags, setTags] = useState<Tag[]>([]);
    const [sortedTags, setSortedTags] = useState<Tag[]>([]);

    // TODO: this is a workaround to disable the colorpickers, as long as the App does not retrieve values from backend
    const colorPickerEnabled = false;

    useEffect(() => {
        async function getTags() {
            try {
                setTags(await client.getTags());
            } catch (err) {
                showToast("Could not retrieve tags information", { severity: "error" });
            }
            done();
        }
        getTags();
    }, []);

    useEffect(() => {
        setSortedTags(tags.sort((t1, t2) => {
            return t1.name.localeCompare(t2.name)
        }));
    }, [tags]);

    const updateTagAccount = (id: number, account: string): void => {
        const idx = tags.findIndex(t => t.id === id);
        const tag = tags[idx];
        if (tag) {
            tag.account = account;
            tags[idx] = tag;
            setTags([...tags]); // create copy to trigger setTags
        }
    }

    const updateTagColor = (id: number, color: string): void => {
        const idx = tags.findIndex(t => t.id === id);
        const tag = tags[idx];
        if (tag) {
            tag.color = color;
            tags[idx] = tag;
            setTags([...tags]); // create copy to trigger setTags
        }
    }

    const saveTag = (tag: Tag) => {
        async function save() {
            try {
                await client.updateTag(tag);
                updateTagColor(tag.id, tag.color);
                showToast(`Tag '${tag.name}' updated`, { severity: "success" });
            } catch (err) {
                showToast(`Could not save tag '${tag.name}'`, { severity: "error" });
            }
        }
        save();
    }

    return (
        <Container>
            <Typography variant="h4" paddingTop={2}>
                Tags
            </Typography>

            <Divider className="tags-divider" />

            {Loader(
                <>
                    <Container
                        className="tags-container"
                        component={Paper}
                        variant="outlined"
                        sx={{ borderRadius: 4 }}
                    >
                        <div className="tag-row">
                            <span className="tag-header"> {"Name"} </span>
                            <span className="tag-header"> {"Account"} </span>
                        </div>
                        {sortedTags?.map((tag) => (
                            <div className="tag-row" key={`tag-${tag.id}`}>
                                <span className="tag-label"> {tag.name}</span>
                                <TextField
                                    placeholder="Not set"
                                    className="tag-text-field"
                                    value={tag.account}
                                    onChange={e => updateTagAccount(tag.id, e.target.value)}
                                    onBlur={() => saveTag(tag)}
                                />
                                {colorPickerEnabled && (
                                    <>
                                        <ColorPickerCircle color={tag.color} />
                                        <ColorPicker
                                            onChange={(e) => { saveTag({ ...tag, color: e.hex })}}
                                        />
                                    </>
                                )}
                            </div>
                        ))}

                    </Container>
                </>
            )}
        </Container>
    );
};
