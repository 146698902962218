import { createBrowserRouter } from "react-router-dom";

import { Login } from "./routes/Login";
import { Users } from "./routes/Users/Index";
import { ResetPassword } from "./routes/ResetPassword/Index";
import { Root } from "./routes/Root";
import { RequestToken } from "./routes/ResetPassword/RequestToken";
import { ResetPassword as ResetPasswordComp } from "./routes/ResetPassword/ResetPassword";
import { Error } from "./routes/Error";
import { EditUserDetail } from "./routes/Users/UserDetail/EditUserDetail";
import { CreateUserDetail } from "./routes/Users/UserDetail/CreateUserDetail";
import { Tags } from "./routes/Tags/Tags";

export const router = createBrowserRouter([
  {
    path: "/login",
    Component: Login,
    ErrorBoundary: Error,
  },
  {
    path: "/reset-password",
    Component: ResetPassword,
    ErrorBoundary: Error,
    children: [
      {
        index: true,
        Component: RequestToken,
      },
      {
        path: "token",
        Component: ResetPasswordComp,
      },
    ],
  },
  {
    path: "/",
    Component: Root,
    ErrorBoundary: Error,
    children: [
      {
        path: "users",
        Component: Users,
        children: [
          {
            path: "create",
            Component: CreateUserDetail,
          },
          {
            path: ":userId",
            Component: EditUserDetail,
          },
        ],
      },
      {
        path: "tags",
        Component: Tags,
      },
    ],
  },
]);
