import { FC, useEffect } from "react";
import { useNavigate, useRouteError } from "react-router-dom";
import { ClientError } from "../api";

export const Error: FC = () => {
  const navigate = useNavigate();
  const error = useRouteError();

  const isUnauthorized =
    error instanceof ClientError && error.statusCode === 401;

  useEffect(() => {
    if (isUnauthorized) navigate("/login");
  }, [isUnauthorized]);

  return <>An unexpected error occured: {error}</>;
};
