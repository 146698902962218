import { FC } from "react";
import { MenuBar } from "../components/MenuBar";
import { Navigate, Outlet, useMatch } from "react-router-dom";
import { Protected } from "../components/client/Protected";
import { Box, Container } from "@mui/material";
import { Sidebar } from "../components/sidebar/Sidebar";

export const Root: FC = () => {
  const match = useMatch({
    path: "/",
    end: true,
  });

  return (
    <Protected>
      {match && <Navigate to="users" replace />}
      <MenuBar />
      <Box sx={{ display: "flex"}}>
        <Sidebar />
        <Container component="main">
          <Outlet />
        </Container>
      </Box>
    </Protected >
  );
};
