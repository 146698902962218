import { FC } from "react";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, Outlet } from "react-router-dom";
import { Container } from "@mui/material";
import { LockOutlinedIcon } from "../../components/icons";

export const ResetPassword: FC = () => {
  return (
    <Container
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      maxWidth="xs"
    >
      <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Reset password
      </Typography>
      <Box sx={{ mt: 1 }}>
        <Outlet />
      </Box>
      <Box sx={{ mt: 1 }}>
        <Link to="/">Back to login</Link>{" "}
      </Box>
    </Container>
  );
};
