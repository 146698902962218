import { FC, PropsWithChildren, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useClient } from "./Client";

export const Protected: FC<PropsWithChildren> = ({ children }) => {
  const { isLoggedIn } = useClient();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn]);

  return isLoggedIn ? <>{children}</> : null;
};
