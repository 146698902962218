import { FC, useState } from "react";
import { CirclePicker } from 'react-color'
import { purple, blue, lightBlue, cyan, teal, green, lightGreen, yellow, amber, orange, deepOrange, red } from '@mui/material/colors';
import { Button } from "../Button";
import { Popover } from "@mui/material";

const shade = 500;

const colors = [
    purple[shade],
    blue[shade],
    lightBlue[shade],
    cyan[shade],
    teal[shade],
    green[shade],
    lightGreen[shade],
    yellow[shade],
    amber[shade],
    orange[shade],
    deepOrange[shade],
    red[shade],
];

export interface ColorPickerProps {
    onChange: (e: any) => void;
}

export const ColorPicker: FC<ColorPickerProps> = (
    {
        onChange,
    }
) => {

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    return (
        <div>
            <Button
                onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                }}>
                Pick Color
            </Button>

            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
            >
                <div className="colors-container">
                    <CirclePicker
                        colors={colors}
                        onChange={(e) => {
                            onChange(e);
                            setAnchorEl(null);
                        }}
                    />
                </div>
            </Popover>
        </div>
    )
};