import { FC, useEffect, useState } from "react";
import { Table } from "../../components/table/Table";
import { User } from "../../api/types";
import {
  Container,
  DialogContentText,
  Divider,
  Stack,
  Typography,
} from "@mui/material";

import { Button } from "../../components/Button";
import { Column } from "../../components/table/Column";
import { Action } from "../../components/table/Action";
import { Outlet, useMatch, useNavigate } from "react-router-dom";
import { useClient } from "../../components/client/Client";
import { useFeedback } from "../../components/feedback/Index";
import { AddIcon, ContactPageIcon, DeleteIcon } from "../../components/icons";

const columns: Column[] = [
  { key: "email", title: "Email" },
  { key: "firstname", title: "Firstname" },
  { key: "lastname", title: "Lastname" },
];

export const Users: FC = () => {
  const navigate = useNavigate();
  const { client, me } = useClient();
  const { showConfirm, showToast } = useFeedback();

  const [users, setUsers] = useState<User[]>([]);

  const match = useMatch({ path: "/users", end: true });
  const isUsersRoute = !!match;

  const refreshUsers = async () => {
    try {
      setUsers(await client.getUsers());
    } catch (err) {
      showToast("Could not refresh users", { severity: "error" });
    }
  };

  useEffect(() => {
    // refresh users only if this route is matched completely
    if (isUsersRoute) refreshUsers();
  }, [isUsersRoute]);

  const actions: Action<User>[] = [
    {
      title: "View details",
      icon: <ContactPageIcon />,
      onClick: (user) => {
        navigate(`${user.id}`);
      },
    },
    {
      title: "Delete",
      icon: <DeleteIcon />,
      onClick: (user) => {
        showConfirm({
          title: "Confirm deletion",
          children: (
            <>
              <DialogContentText>
                Do you really want to delete user <b>{user.email}</b>?
              </DialogContentText>
              <Typography
                component="span"
                fontSize="0.8em"
                color={(theme) => theme.palette.grey[600]}
              >
                You can&apos;t undo this operation
              </Typography>
            </>
          ),
          onConfirm: async (close) => {
            await client.deleteUser(user);

            await refreshUsers();

            close();
          },
        });
      },
      disabled: (user) => user.id === me?.id,
    },
  ];

  const onCreate = () => {
    navigate("create", { relative: "route" });
  };

  return (
    <Container>
      <Typography variant="h4" paddingTop={2}>
        Users
      </Typography>

      <Divider />
      <Stack direction="row" spacing={2} padding={2}>
        <Button onClick={onCreate} startIcon={<AddIcon />}>
          Create
        </Button>
      </Stack>
      <Table columns={columns} rows={users} actions={actions} />

      <Outlet />
    </Container>
  );
};
