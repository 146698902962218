import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
} from "react";
import { ShowConfirm, useConfirm } from "./useConfirm";
import { ConfirmDialog } from "./ConfirmDialog";
import { Toast } from "./Toast";
import { ShowToast, useToast } from "./useToast";
import { NoOp } from "./noop";

interface FeedbackContext {
  showConfirm: ShowConfirm;
  showToast: ShowToast;
}

const FeedbackContext = createContext<FeedbackContext>({
  showConfirm: NoOp,
  showToast: NoOp,
});

export const useFeedback = () => useContext(FeedbackContext);

export const Feedback: FC<PropsWithChildren> = ({ children }) => {
  const { showConfirm, confirmProps } = useConfirm();
  const { showToast, toastProps } = useToast();

  const context = useMemo<FeedbackContext>(
    () => ({
      showConfirm,
      showToast,
    }),
    [showConfirm, showToast]
  );

  return (
    <FeedbackContext.Provider value={context}>
      {children}
      <ConfirmDialog {...confirmProps} />
      <Toast {...toastProps} />
    </FeedbackContext.Provider>
  );
};
