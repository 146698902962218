import { FC, ReactNode, useEffect, useState } from "react";
import { Loading } from "./Loading";

export type LoaderTask<T> = () => Promise<T | undefined>;

export const useLoader = <T,>(task?: LoaderTask<T>, initialData?: T) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<ReactNode>();
  const [data, setData] = useState(initialData);

  const loader = (children: ReactNode) => (
    <Loader loading={loading}>{error ?? children}</Loader>
  );

  const done = () => setLoading(false);
  const onError = (error: ReactNode) => {
    setError(error);
    done();
  };
  const refreshData = async () => {
    if (!task) return;

    try {
      setData(await task());
      done();
    } catch (err) {
      onError("Failed to load data");
      return;
    }
  };

  useEffect(() => {
    if (!task) return;
    refreshData();
  }, []);

  return {
    Loader: loader,
    data,
    reload: refreshData,
    done,
    onError,
  };
};

export interface LoaderProps {
  loading: boolean;
  children: ReactNode;
}

export const Loader: FC<LoaderProps> = ({ loading, children }) => {
  return loading ? <Loading /> : <>{children}</>;
};
