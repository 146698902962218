import { ValidateFunction } from "ajv";
import { ReactElement, useState } from "react";
import { ValidationErrors, transformErrors } from "../validation";
import { TextField } from "./TextField";
import { TextFieldProps } from "@mui/material";

export enum InputType {
  text,
  password,
}

export interface InputProps<T> {
  label: string;
  type: InputType;

  required?: boolean;
  autoComplete?: string | boolean;

  valueProp: keyof T;
}

export interface FormProps<T> {
  inputs: InputProps<T>[];
  initialValue: T;
  validate: ValidateFunction<T>;

  onSubmit: (data: T) => void;
  onError?: (errors: ValidationErrors<T>) => void;

  children: (opts: {
    inputs: ReactElement | ReactElement[];
    submit: () => void;
  }) => ReactElement;

  textFieldProps?: TextFieldProps;
}

export const Form = <T,>({
  inputs,
  initialValue,
  validate,
  onSubmit,
  children,
  textFieldProps,
}: FormProps<T>): ReactElement => {
  const [data, setData] = useState<T>(initialValue);
  const [errors, setErrors] = useState<ValidationErrors<T>>();

  const submit = () => {
    const isValid = validate(data);

    if (!isValid) {
      setErrors(transformErrors(validate.errors));
      return;
    }

    setErrors(undefined);

    onSubmit(data);
  };

  const inputElements = (
    <>
      {inputs.map((input, index) => {
        const {
          type: inputType,
          valueProp,
          label,
          required,
          autoComplete,
        } = input;

        const value = data[valueProp];

        const onChange = (value: any) => {
          setData((data) => ({ ...data, [valueProp]: value }));
        };

        const props = {
          key: `form-input-${index}`,
          required,
          error: !!errors?.[valueProp],
          helperText: errors?.[valueProp],
          label,
          value,
          autoComplete: autoComplete
            ? typeof autoComplete === "string"
              ? autoComplete
              : String(valueProp)
            : inputType === InputType.password
            ? "new-password"
            : undefined,
        };

        switch (inputType) {
          case InputType.text:
            return (
              <TextField
                type="text"
                onChange={(event) => onChange(event.currentTarget.value)}
                {...props}
                {...textFieldProps}
              />
            );
          case InputType.password:
            return (
              <TextField
                type="password"
                onChange={(event) => onChange(event.currentTarget.value)}
                {...props}
                {...textFieldProps}
              />
            );
          default:
            return <span>Invalid type {input.type}</span>;
        }
      })}
    </>
  );

  return children({ inputs: inputElements, submit });
};
