import { FC, useState, ChangeEvent } from "react";

import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

import { Link, Navigate, useNavigate } from "react-router-dom";

import Logo from "../assets/Logo-Color-512.png";
import { Button } from "../components/Button";
import { useClient } from "../components/client/Client";
import { useFeedback } from "../components/feedback/Index";

export const Login: FC = () => {
  const { client, isLoggedIn } = useClient();
  const { showToast } = useFeedback();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const onChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  };
  const onChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
  };

  const onLogin = async () => {
    try {
      await client.login(email, password);
    } catch (err) {
      showToast("Login failed", { severity: "error" });
      return;
    }

    navigate("/", { replace: true });
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onLogin();
      return;
    }
  };

  return isLoggedIn ? (
    <Navigate to="/" replace />
  ) : (
    <Container
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      maxWidth="xs"
    >
      <Box component="img" src={Logo} alt="logo" sx={{ width: 224 }} />
      <Box sx={{ mt: 1 }}>
        <TextField
          value={email}
          onChange={onChangeEmail}
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          autoComplete="username"
          type="email"
          autoFocus
        />
        <TextField
          value={password}
          onChange={onChangePassword}
          onKeyDown={onKeyDown}
          margin="normal"
          required
          fullWidth
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        <Button onClick={onLogin} fullWidth sx={{ mt: 3, mb: 2 }}>
          Sign In
        </Button>

        <Box sx={{ textAlign: "center" }}>
          <Link to="/reset-password">Reset password</Link>
        </Box>
      </Box>
    </Container>
  );
};
