import { Alert, AlertColor, Snackbar } from "@mui/material";
import { FC } from "react";

export interface ToastProps {
  open: boolean;
  duration: number;
  severity: AlertColor;
  message: string;
  onClose: () => void;
}

export const Toast: FC<ToastProps> = ({
  open,
  duration,
  severity,
  message,
  onClose,
}) => {
  return (
    <Snackbar open={open} autoHideDuration={duration} onClose={onClose}>
      <Alert onClose={onClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};
