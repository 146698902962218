import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

import { RouterProvider } from "react-router-dom";

import "./App.css";
import { router } from "./router";
import { defaultTheme } from "./theme";
import { Client } from "./components/client/Client";
import { isDebug } from "./api";
import { useEffect, useState } from "react";
import { useLoader } from "./components/Loader";
import { Feedback } from "./components/feedback/Index";

export default function App() {
  const { Loader, done } = useLoader();
  const [apiUrl, setApiUrl] = useState("");

  useEffect(() => {
    (async () => {
      const response = await fetch(
        isDebug ? "/config.local.json" : "/config.json"
      );
      const json = await response.json();

      setApiUrl(json.apiUrl);

      done();
    })();
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Feedback>
        {Loader(
          <Client url={apiUrl}>
            <CssBaseline />

            <RouterProvider router={router} />
          </Client>
        )}
      </Feedback>
    </ThemeProvider>
  );
}
